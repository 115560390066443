import React, { useCallback, useState } from 'react'
import { Link } from "gatsby"
import useWindowSize from '../hooks/useWindowSize'
import classNames from 'classnames'
import Logo from "../components/svg/logo"
import Chart from "../components/svg/chart"

export default function Header() {
  const { width } = useWindowSize();
  const useSmallMenu = width < 3000;

  const [isMobileOpen, setMobileOpen] = useState(false);
  const onToggleMobileOpen = useCallback(() => setMobileOpen(!isMobileOpen), [isMobileOpen]);

  return (
    <header>
        <div className="container-lg">
            <div className="row">
                <div className="navbar">
                    <Link to="/" className="logo" aria-label="Madclarity logo">
                        <Logo />
                    </Link>

                    {(!useSmallMenu || isMobileOpen) &&
                    (<nav className={classNames({'mobile-menu': useSmallMenu})}>
                        <div className="container-lg">
                            <div className="row">
                                <div className="close-btn">
                                    <button onClick={onToggleMobileOpen}>
                                        <div id="nav-icon4" className="open">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className="row nav-row">
                                <div className="nav-open-left">
                                    <Chart />
                                    <p className="lead text-center">Work more effectively with your media &amp; advertising agencies and achieve better outcomes.</p>
                                </div>
                                <div className="nav-open-right">
                                    <Link to="/" className='nav-link'>Home</Link>
                                    <Link to="/about" className='nav-link'>About us</Link>
                                    <Link to="/how-we-help" className='nav-link'>How we help</Link>
                                    <Link to="/what-we-do" className='nav-link'>What we do</Link>
                                    <Link to="/why-we-exist" className='nav-link'>Why we exist</Link>
                                    <Link to="/careers" className='nav-link'>Careers</Link>
                                    <Link to="/contact" className='btn btn-lg btn-nav'>
                                        Contact us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </nav>)}
                    
                    <div className="nav-right">
                        {useSmallMenu &&
                        (<button onClick={onToggleMobileOpen} className='btn-mob-nav' aria-label="Navigation menu">
                                {isMobileOpen ? (<div id="nav-icon4" className="open">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>) : (<div id="nav-icon4">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>)}
                        </button>)}
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

