import { Link } from "gatsby"
import React from "react"
import CurveTop from "../components/svg/curveTop"
import Direction from "../components/svg/direction"
import MapPin from "../components/svg/mapPin"
import Linkedin from "../components/svg/linkedin"

const Footer = () => (
  <>
    <div className="curve-top">
        <CurveTop />
    </div>
    <footer className="bg">
      <div className="container">
          <div className="row footer-row">
              <div className="footer-img">
                  <Direction />
              </div>
              <div className="footer-content">
                <div className="footer-content-top">
                  <Link to="/">Home</Link>
                  <Link to="/about">About</Link>
                  <Link to="/how-we-help">How we help</Link>
                  <Link to="/what-we-do">What we do</Link>
                  <Link to="/why-we-exist">Why we exist</Link>
                  <Link to="/careers">Careers</Link>
                  <Link to="/contact">Contact</Link>
                </div>
                <div className="footer-content-mid">
                  <div className="schema-style" itemScope itemType="http://schema.org/LocalBusiness">
                    <MapPin />
                    <ul className="list-unstyled list-inline" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                      <li itemProp="streetAddress">Level 2, 86 Liverpool Street,</li>
                      <li itemProp="addressLocality">Sydney,</li>
                      <li itemProp="addressRegion">NSW,</li>
                      <li itemProp="postalCode">2000,</li>
                      <li itemProp="addressCountry">Australia</li>
                    </ul>
                  </div>
                  <a className="li-icon" href="https://www.linkedin.com/company/madclarity/about/" target="_blank" rel="noreferrer" aria-label="Linkedin"><Linkedin /></a>
                </div>
                <div className="footer-content-end">
                  <span className="mr-05">© Madclarity {new Date().getFullYear()}. All rights reserved.</span>
                  <Link className="mr-05" to="/terms">Terms</Link>
                  <Link to="/faulkner-media-training">Faulkner Media Training</Link>
                </div>
              </div>
          </div>
      </div>
    </footer>
  </>
)

export default Footer