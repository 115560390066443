import React from "react"

const Direction = () => (
    <svg width="340" height="687" viewBox="0 0 340 687" fill="none" xmlns="http://www.w3.org/2000/svg" className="img-resp">
        <desc>Illustration of a man at the top of a directional sign post pointing towards the right direction</desc>
        <path d="M263.279 451.476L267.948 473.359C272.664 461.517 282.003 456.801 282.003 456.801C282.003 456.801 281.026 451.613 279.958 446.028C279.744 444.914 279.531 443.785 279.317 442.671C278.508 438.52 277.547 433.652 276.479 428.326C273.167 411.662 268.788 390.603 264.652 373.573C262.638 365.287 260.685 357.947 258.945 352.53L244.646 364.341L258.853 430.783L263.279 451.476Z" fill="#eff1f6"/>
        <path d="M292.7 365.959L261.295 350.805C260.608 351.248 260.227 351.477 260.227 351.477L258.945 352.53C260.684 357.947 262.638 365.287 264.652 373.573L285.833 384.484C296.301 378.365 292.7 365.959 292.7 365.959Z" fill="white"/>
        <path d="M267.948 473.359C260.441 485.46 262.913 501.88 265.598 511.966L295.508 455.977L296.912 455.947C293.112 452.651 286.657 447.386 279.317 442.671C279.531 443.785 279.744 444.914 279.958 446.028C281.026 451.613 282.003 456.801 282.003 456.801C282.003 456.801 272.664 461.517 267.948 473.359Z" fill="white"/>
        <path d="M282.766 435.544C285.284 439.085 288.122 439.802 288.122 439.802L316.033 377.22L310.387 374.504L282.766 435.544Z" fill="#61D0EC"/>
        <path d="M295.508 455.978L265.598 511.967C267.063 517.491 268.589 521.107 268.589 521.107L271.687 515.11L285.818 487.688L300.529 459.167C300.529 459.167 299.201 457.916 296.912 455.947L295.508 455.978Z" fill="#61D0EC"/>
        <path d="M255.557 212.992C255.557 212.168 254.901 211.512 254.077 211.512C253.253 211.512 252.581 212.168 252.581 212.992C252.581 213.816 253.253 214.487 254.077 214.487C254.901 214.487 255.557 213.816 255.557 212.992ZM249.697 216.639C249.697 215.815 249.026 215.144 248.202 215.144C247.378 215.144 246.722 215.815 246.722 216.639C246.722 217.463 247.378 218.119 248.202 218.119C249.026 218.119 249.697 217.463 249.697 216.639ZM240.419 205.728C235.704 195.397 238.115 165.655 240.923 142.78C242.922 126.59 245.104 113.832 245.104 113.832L271.763 119.921L319.542 159.521C316.246 163.794 314.278 169.135 314.278 174.964C314.278 178.901 315.178 182.609 316.765 185.936L300.94 196.175L257.51 224.254L251.406 228.206L247.088 222.835L239.687 213.648C239.534 211.054 239.809 208.399 240.419 205.728Z" fill="#61D0EC"/>
        <path d="M319.543 159.521C316.246 163.794 314.278 169.135 314.278 174.964C314.278 178.901 315.178 182.609 316.765 185.936L322.35 182.319C329.477 178.367 330.392 168.494 324.121 163.305L319.543 159.521Z" fill="#eff1f6"/>
        <path d="M280.233 409.328C278.707 417.354 278.768 423.306 279.622 427.701C280.309 431.226 281.499 433.759 282.766 435.544L310.387 374.504L292.7 365.958C292.7 365.958 296.301 378.365 285.833 384.484L292.212 387.78C292.074 387.78 284.399 387.46 280.233 409.328Z" fill="white"/>
        <path d="M235.612 213.602L235.628 213.816C238.115 213.633 239.687 213.648 239.687 213.648C239.534 211.054 239.809 208.399 240.419 205.728C240.419 205.728 240.404 205.728 240.419 205.713C235.704 195.397 238.115 165.655 240.923 142.781L233.262 126.59L244.417 114.58L244.28 114.458C238.817 117.891 233.705 119.692 229.249 118.41L218.369 204.141L218.018 204.843L235.612 213.602Z" fill="white"/>
        <path d="M245.104 113.832L244.417 114.58L233.262 126.59L240.923 142.78C242.922 126.59 245.104 113.832 245.104 113.832Z" fill="#58CAE8"/>
        <path d="M227.341 112.321L227.54 113.832L229.249 118.395V118.41C233.705 119.692 238.817 117.891 244.28 114.458L239.427 107.423L239.153 107.026C235.597 109.681 231.553 111.436 227.341 112.321Z" fill="white"/>
        <path d="M229.569 251.752C230.027 252.027 230.531 252.302 231.08 252.561C233.216 253.538 235.689 252.103 235.795 249.753C237.031 223.598 251.406 228.206 251.406 228.206L247.088 222.835L239.687 213.648C239.687 213.648 238.115 213.633 235.627 213.816C229.935 214.243 219.391 215.754 211.654 221.095C209.09 222.865 206.832 225.047 205.153 227.764C202.986 231.289 201.704 234.173 201.079 236.569C199.08 244 203.246 246.594 205.794 248.105L205.825 248.075C207.885 250.226 210.967 249.479 210.967 249.479C213.806 252.546 217.453 252.363 220.016 250.501L222.534 250.47C223.526 250.806 224.549 251.081 225.541 251.447V251.432L228.654 253.294L229.569 251.752Z" fill="white"/>
        <path d="M225.541 251.432V251.447L237.932 286.942C283.163 290.696 305.519 281.158 314.034 276.001C316.994 274.2 318.276 272.933 318.276 272.933C319.161 231.899 304.679 202.935 300.941 196.175L257.511 224.254L284.765 238.461L289.435 271.148L266.392 275.817L228.654 253.294L225.541 251.432Z" fill="#61D0EC"/>
        <path d="M289.435 271.148L284.765 238.461L257.511 224.254L251.407 228.206C251.407 228.206 237.032 223.597 235.795 249.753C235.689 252.103 233.217 253.538 231.08 252.561C230.531 252.302 230.027 252.027 229.569 251.752L228.654 253.294L266.392 275.817L289.435 271.148Z" fill="white"/>
        <path d="M215.362 203.515L218.018 204.843L218.368 204.141L229.249 118.41V118.395L223.465 121.37L223.45 121.386C221.604 123.797 216.369 130.847 212.829 137.622C208.48 145.954 215.729 151.555 212.829 155.187C209.93 158.803 208.48 155.37 208.129 159.704C207.992 161.291 208.938 167.608 210.174 174.964C212.31 187.675 215.362 203.515 215.362 203.515Z" fill="#58CAE8"/>
        <path d="M199.797 195.763L211.654 221.08V221.095C219.391 215.754 229.936 214.243 235.628 213.816L235.612 213.602L218.018 204.843L215.362 203.516L199.797 195.763Z" fill="white"/>
        <path d="M250.17 103.944C247.012 105.546 245.074 104.325 244.265 102.173C242.723 104.035 240.999 105.653 239.153 107.026C235.597 109.681 231.553 111.436 227.341 112.321C215.164 114.855 201.567 110.109 194.074 98.5569C190.076 92.4071 187.101 85.4638 186.185 78.1389C185.88 75.7584 185.804 73.332 185.987 70.9209C186.887 58.9876 189.908 47.2831 195.066 36.4638L195.204 36.1738C192.457 34.8157 189.802 33.3049 187.238 31.6569C180.432 27.2772 171.749 19.7082 174.694 10.537C176.205 5.80635 180.936 2.8459 185.666 2.1592C191.236 1.35042 196.791 3.73099 201.262 6.8593C205.184 9.6061 208.556 13.0549 212.02 16.3816C216.614 20.7917 221.466 25.034 226.99 28.2539C231.37 30.8175 236.055 32.4962 241.06 33.3355C247.042 34.3579 253.177 34.602 258.899 36.8147C262.684 38.2797 265.247 42.7967 265.369 47.5273C271.504 48.7634 275.548 53.0514 275.594 59.3843C275.624 64.2981 274.068 69.4865 272.572 74.1255C271.016 78.9325 268.955 83.602 266.239 87.8748C262.272 94.1315 256.885 100.525 250.17 103.944ZM198.546 66.4497C200.102 66.4497 201.369 65.1832 201.369 63.6266C201.369 62.0548 200.102 60.8035 198.546 60.8035C196.974 60.8035 195.723 62.0548 195.723 63.6266C195.723 65.1832 196.974 66.4497 198.546 66.4497Z" fill="white"/>
        <path d="M205.794 248.105L212.692 267.348L219.269 285.691L230.454 316.928L231.141 317.477C235.368 312.198 239.671 307.299 243.761 303.454L243.654 303.331L237.932 286.942L225.541 251.447C224.549 251.081 223.526 250.806 222.534 250.471L220.017 250.501C217.453 252.363 213.806 252.546 210.967 249.479C210.967 249.479 207.885 250.226 205.825 248.075L205.794 248.105Z" fill="#eff1f6"/>
        <path d="M188.428 323.688C178.891 338.612 208.511 346.685 208.511 346.685L209.731 347.173C215.5 338.674 223.221 327.381 231.141 317.477L230.454 316.928L219.269 285.691L219.193 285.721C219.193 285.721 213.531 291.886 206.725 299.898C200.362 307.39 193.037 316.485 188.428 323.688Z" fill="white"/>
        <path d="M174.648 146.489C180.447 157.445 186.49 168.997 191.267 178.321L210.174 174.964C208.938 167.609 207.992 161.291 208.129 159.704C208.48 155.37 209.93 158.803 212.829 155.187C215.728 151.555 208.48 145.955 212.829 137.623C216.369 130.847 221.604 123.797 223.45 121.386L174.648 146.489Z" fill="#61D0EC"/>
        <path d="M243.761 303.453C239.671 307.299 235.368 312.197 231.141 317.477C223.221 327.381 215.5 338.673 209.731 347.173C207.29 350.775 205.184 353.872 203.581 356.131C199.049 362.525 188.611 377.327 185.605 390.252C184.14 396.555 186.994 403.086 192.457 406.55L263.263 451.476H263.279L258.853 430.783L216.354 387.704L244.646 364.341L258.945 352.53L260.227 351.477C260.227 351.477 260.608 351.248 261.295 350.805C268.07 346.517 304.908 322.544 314.034 303.346C320.672 289.353 314.034 276 314.034 276C305.519 281.158 283.163 290.696 237.932 286.942L243.654 303.331L243.761 303.453Z" fill="white"/>
        <path d="M206.725 299.898C213.531 291.886 219.192 285.721 219.192 285.721L219.269 285.691L212.692 267.348L212.585 267.394L162.273 250.623L147.608 267.394L156.901 283.768L206.527 299.73L206.725 299.898Z" fill="white"/>
        <path d="M62.3345 161.764L61.8767 181.617H61.9225L129.326 186.622C151.026 187.294 151.026 186.622 173.244 182.365L174.618 181.693C169.567 171.408 164.333 161.016 159.48 151.86L130.12 164.144L62.3345 161.764Z" fill="#61D0EC"/>
        <path d="M135.934 100.51C133.263 101.899 132.317 105.241 133.858 107.835L146.997 129.932C149.927 134.479 153.498 140.736 157.389 147.938C158.076 149.22 158.778 150.533 159.48 151.86C164.333 161.016 169.567 171.408 174.618 181.694C176.48 185.493 178.326 189.278 180.096 192.971C186.597 206.4 192.381 218.76 196.135 226.833C198.912 232.799 200.56 236.431 200.56 236.431L201.079 236.569C201.705 234.173 202.986 231.289 205.153 227.764C206.832 225.047 209.09 222.865 211.654 221.095V221.08L199.797 195.763C198.576 192.818 195.402 186.394 191.267 178.321C186.49 168.997 180.447 157.445 174.648 146.489C164.348 126.986 154.856 109.33 154.856 109.33L148.89 99.3352C147.47 96.9546 144.449 96.1 141.992 97.3666L135.934 100.51Z" fill="#eff1f6"/>
        <path d="M80.1889 223.308L60.5034 250.623L90.9624 260.71L196.135 226.833C192.381 218.76 186.597 206.4 180.096 192.971L80.1889 223.308Z" fill="white"/>
        <path d="M285.818 487.688L271.687 515.11C282.949 559.608 280.248 648.483 278.539 684.985V685H295.325V684.985C305.656 607.754 285.818 487.688 285.818 487.688Z" fill="#eff1f6"/>
        <path d="M198.546 66.4497C200.105 66.4497 201.369 65.1857 201.369 63.6266C201.369 62.0674 200.105 60.8035 198.546 60.8035C196.987 60.8035 195.723 62.0674 195.723 63.6266C195.723 65.1857 196.987 66.4497 198.546 66.4497Z" fill="#272223"/>
        <path d="M246.722 216.639C246.722 217.463 247.378 218.119 248.202 218.119C249.026 218.119 249.697 217.463 249.697 216.639C249.697 215.815 249.026 215.144 248.202 215.144C247.378 215.144 246.722 215.815 246.722 216.639Z" fill="#272223"/>
        <path d="M252.582 213.992C252.582 214.816 253.253 215.487 254.077 215.487C254.901 215.487 255.557 214.816 255.557 213.992C255.557 213.168 254.901 212.512 254.077 212.512C253.253 212.512 252.582 213.168 252.582 213.992Z" fill="#272223"/>
        <path d="M276.784 154.287L295.127 168.341L239.687 213.648L247.088 222.835L251.407 228.206L257.511 224.254L300.941 196.175L316.765 185.936L322.35 182.319C329.477 178.367 330.393 168.494 324.121 163.305L319.543 159.521L271.763 119.921L245.104 113.832C245.104 113.832 242.922 126.59 240.923 142.78C238.115 165.655 235.704 195.397 240.419 205.713" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M253.558 124.896C255.679 126.757 257.846 128.68 259.052 131.229C260.257 133.777 260.272 137.104 258.304 139.118C257.098 140.354 255.359 140.919 253.924 141.895C249.209 145.115 248.858 152.348 251.651 157.338C254.428 162.328 259.448 165.609 264.255 168.677C264.255 168.677 244.57 187.309 240.419 205.713C240.404 205.728 240.419 205.728 240.419 205.728C239.809 208.399 239.534 211.054 239.687 213.648C239.687 213.648 238.115 213.633 235.627 213.816C229.935 214.243 219.391 215.754 211.654 221.095C209.09 222.865 206.832 225.047 205.153 227.764C202.986 231.289 201.704 234.173 201.079 236.569C199.08 244 203.246 246.594 205.794 248.105L212.692 267.348L219.269 285.691L230.454 316.928" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M300.941 196.175C304.679 202.935 319.161 231.899 318.276 272.933C318.276 272.933 316.994 274.2 314.034 276.001C305.519 281.158 283.163 290.696 237.932 286.942C237.871 286.942 237.825 286.927 237.764 286.927" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M245.104 113.832L244.417 114.58L233.262 126.59L240.923 142.78" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M229.249 118.41L218.368 204.141" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M279.317 442.671C278.508 438.52 277.547 433.652 276.479 428.326C273.167 411.662 268.788 390.603 264.652 373.573" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M282.766 435.56C285.284 439.085 288.122 439.802 288.122 439.802L316.033 377.22L310.386 374.504L292.7 365.959L261.295 350.805" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M258.945 352.53C260.684 357.947 262.638 365.287 264.652 373.573L285.833 384.484L292.212 387.78C292.074 387.78 284.399 387.46 280.233 409.328C278.707 417.354 278.768 423.306 279.622 427.701C280.309 431.226 281.499 433.759 282.766 435.544" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M267.948 473.359C272.664 461.517 282.003 456.801 282.003 456.801C282.003 456.801 281.026 451.613 279.958 446.028C279.744 444.914 279.531 443.785 279.317 442.671C286.657 447.386 293.112 452.651 296.912 455.947C299.201 457.915 300.529 459.167 300.529 459.167L285.818 487.688L271.687 515.11L268.589 521.107C268.589 521.107 267.063 517.491 265.598 511.966C262.913 501.88 260.441 485.46 267.948 473.359Z" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.646 364.341L258.853 430.783L263.279 451.476L267.948 473.359" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M250.46 298.036C248.324 299.425 246.081 301.271 243.761 303.453C239.671 307.299 235.368 312.197 231.141 317.477C223.221 327.381 215.5 338.674 209.731 347.173C207.29 350.775 205.184 353.873 203.581 356.131C199.049 362.525 188.611 377.327 185.605 390.253C184.14 396.555 186.994 403.086 192.457 406.55L263.263 451.476" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M257.511 224.254L284.765 238.461L289.435 271.148L266.392 275.817L228.654 253.294L225.541 251.432V251.447L237.932 286.942L243.654 303.331" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M212.585 267.394L162.273 250.623L147.608 267.394L156.901 283.768L206.527 299.73" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M219.193 285.721C219.193 285.721 213.531 291.886 206.725 299.898C200.362 307.39 193.037 316.485 188.428 323.688C178.891 338.612 208.511 346.685 208.511 346.685" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M292.7 365.958C292.7 365.958 296.302 378.365 285.833 384.484C285.833 384.499 285.818 384.499 285.818 384.499" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M278.539 684.985C280.248 648.483 282.949 559.608 271.687 515.11" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M295.325 684.985C305.656 607.754 285.818 487.688 285.818 487.688" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M211.654 221.08L199.797 195.763" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M200.56 236.431C200.56 236.431 198.912 232.799 196.135 226.833C192.381 218.76 186.597 206.4 180.096 192.971C178.326 189.278 176.48 185.493 174.618 181.694C169.567 171.408 164.333 161.016 159.48 151.86C158.778 150.533 158.076 149.22 157.389 147.938C153.498 140.736 149.927 134.479 146.997 129.932L133.858 107.835C132.317 105.241 133.263 101.899 135.934 100.51L141.992 97.3666C144.449 96.1 147.47 96.9546 148.89 99.3352L154.856 109.33C154.856 109.33 164.348 126.986 174.648 146.489C180.447 157.445 186.49 168.997 191.267 178.321C195.402 186.394 198.576 192.818 199.797 195.763L215.362 203.515L218.018 204.843L235.612 213.602" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M223.45 121.386C221.604 123.797 216.369 130.847 212.829 137.623C208.48 145.955 215.729 151.555 212.829 155.187C209.93 158.803 208.48 155.37 208.129 159.704C207.992 161.291 208.938 167.609 210.174 174.964C212.31 187.675 215.362 203.515 215.362 203.515" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M224.122 120.516C224.122 120.516 223.877 120.821 223.465 121.371" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.174 174.964L191.267 178.321" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M180.096 192.971L80.1889 223.308L60.5034 250.623L90.9624 260.71L196.135 226.833" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M228.043 237.194C228.043 237.194 221.07 246.518 229.569 251.752C230.027 252.027 230.531 252.302 231.08 252.561C233.217 253.538 235.689 252.103 235.796 249.753C237.032 223.598 251.407 228.206 251.407 228.206" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M225.541 251.447C224.549 251.081 223.527 250.806 222.535 250.47C221.955 250.257 221.36 250.028 220.795 249.753C219.757 249.25 218.796 248.517 218.231 247.51C217.636 246.427 216.736 244.931 216.461 243.726C215.835 240.887 216.003 236.385 217.819 233.837" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.037 231.472C210.037 231.472 206.42 241.864 209.35 247.205C209.838 248.105 210.388 248.868 210.967 249.479C213.806 252.546 217.453 252.363 220.017 250.501" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M210.967 249.479C210.967 249.479 207.885 250.226 205.825 248.075C205.52 247.739 205.245 247.373 205.001 246.915" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M174.648 146.489L223.45 121.386L223.465 121.371L229.249 118.395L227.54 113.832" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M239.427 107.423L244.28 114.458C238.817 117.891 233.705 119.692 229.249 118.41" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M244.265 102.174C242.724 104.035 240.999 105.653 239.153 107.026C235.597 109.682 231.553 111.436 227.341 112.321C215.164 114.855 201.567 110.109 194.075 98.557C190.076 92.4072 187.101 85.4638 186.185 78.139C185.88 75.7585 185.804 73.3321 185.987 70.921C186.887 58.9877 189.909 47.2832 195.066 36.4639" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M245.531 94.1009C243.792 96.1916 243.319 99.7624 244.265 102.173C245.074 104.325 247.012 105.546 250.17 103.944C256.885 100.525 262.272 94.1315 266.239 87.8748C268.955 83.602 271.016 78.9325 272.572 74.1255C274.068 69.4865 275.624 64.2981 275.594 59.3843C275.548 53.0514 271.504 48.7634 265.369 47.5273C265.247 42.7967 262.684 38.2797 258.899 36.8147C253.177 34.602 247.042 34.3579 241.06 33.3355C236.055 32.4962 231.37 30.8175 226.99 28.2539C221.466 25.034 216.614 20.7917 212.02 16.3816C208.556 13.0549 205.184 9.6061 201.262 6.8593C196.791 3.73099 191.236 1.35042 185.666 2.1592C180.936 2.8459 176.205 5.80635 174.694 10.537C171.749 19.7082 180.432 27.2772 187.238 31.6569C189.802 33.3049 192.457 34.8157 195.204 36.1738C199.248 38.1881 203.444 39.8515 207.763 41.1791C211.395 42.2931 215.088 43.1629 218.841 43.7581C222.565 44.3685 226.38 44.9636 229.768 46.688C233.781 48.7023 237.138 52.8073 236.711 57.2632C236.482 59.5827 235.277 61.6581 234.208 63.7182C232.972 66.0682 231.828 68.6319 232.438 71.2872C232.728 72.5843 233.506 73.7593 234.636 74.4155C237.108 75.8499 239.488 74.2171 240.541 72.1417C240.923 71.3787 241.167 70.5699 241.335 69.7306C241.701 67.8842 242.25 66.0682 243.074 64.3744L243.654 63.2146C245.302 59.8421 248.736 57.721 252.475 57.721H252.978C258.396 57.721 262.806 62.1311 262.806 67.5485V72.0044C262.806 75.4684 260.99 78.673 258.014 80.4432L253.116 83.3731" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M205.871 56.5611C204.406 54.6536 201.857 53.6617 199.492 54.0737" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M310.387 374.504L282.766 435.544V435.56" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M295.508 455.977L265.598 511.966" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M255.115 66.175C255.115 66.175 250.415 66.6329 247.622 70.4173C246.691 71.6687 245.959 73.2862 245.684 75.3616" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M247.622 70.4174C247.622 70.4174 253.711 69.6544 255.679 73.7898" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M319.176 685H295.325H278.539H254.428" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M328.5 685H338.221" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M247.057 684.985H242.983" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M258.853 430.783L216.354 387.704L244.646 364.341L258.945 352.53L260.227 351.477C260.227 351.477 260.608 351.248 261.295 350.805C268.07 346.517 304.908 322.544 314.034 303.346C320.672 289.353 314.034 276 314.034 276" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M154.643 216.105C153.941 214.32 154.795 212.137 156.428 211.115C158.045 210.093 160.243 210.199 161.891 211.176C163.539 212.153 164.653 213.908 165.08 215.785C166.027 219.844 163.631 224.361 159.816 226.039C156.001 227.733 151.179 226.573 148.401 223.476C147.608 225.2 146.25 226.665 144.586 227.581C142.969 228.481 141.122 228.847 139.306 229.213C132.653 230.526 126.015 231.853 119.361 233.166" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M203.795 284.683C205.397 281.158 205.535 276.992 204.161 273.376C203.704 272.155 202.941 270.888 201.674 270.583C200.072 270.202 198.561 271.575 197.874 273.071C197.188 274.551 196.974 276.26 196.119 277.649C194.929 279.602 192.457 280.685 190.198 280.258C187.955 279.815 186.078 277.877 185.697 275.619C181.271 276.443 176.525 275.299 172.985 272.536" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M276.372 258.726C275.685 255.049 275.014 251.386 274.327 247.709C274.098 246.488 273.717 245.084 272.572 244.595C271.611 244.199 270.466 244.672 269.78 245.465C269.108 246.244 268.788 247.266 268.498 248.273C268.086 249.753 267.689 251.279 266.789 252.5C265.873 253.736 264.301 254.621 262.821 254.24C261.097 253.797 260.196 251.951 259.525 250.318C254.916 249.448 250.537 247.403 246.92 244.412" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M246.92 222.957L247.088 222.835L258.869 214.823" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M271.489 150.96L268.391 148.366" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M316.765 185.936C315.178 182.609 314.278 178.901 314.278 174.964C314.278 169.135 316.246 163.794 319.543 159.521L319.558 159.505" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M181.577 14.062C181.622 18.0144 184.064 21.6462 187.208 24.0573C190.336 26.4684 194.12 27.8571 197.859 29.1542" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M248.98 41.0113C240.145 41.1639 231.279 38.2188 224.289 32.8167C218.185 28.1014 213.302 21.4937 206.084 18.8232" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M268.879 60.2999C270.588 71.3482 265.812 83.1747 256.9 89.9349" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M260.456 55.7831C258.838 53.6925 256.274 52.3649 253.619 52.2733C250.979 52.197 248.324 53.3568 246.584 55.3558" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M302.726 211.878C308.723 227.886 311.028 245.267 309.395 262.267" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M302.222 270.003C305.61 260.527 305.824 249.936 302.787 240.338" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M295.447 300.462C296.057 305.941 292.624 310.992 289.145 315.28C274.602 333.18 256.061 347.814 235.277 357.81" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M198.668 322.788C196.242 325.214 196.501 329.426 198.363 332.325C200.224 335.21 203.276 337.071 206.283 338.765" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M281.789 376.137C276.967 373.924 272.16 371.727 267.353 369.514" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M221.817 402.766C231.462 411.891 241.106 421.017 250.75 430.142" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M271.641 319.858C263.813 326.572 255.985 333.287 248.141 340.001" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M90.5048 253.294C86.4609 251.584 82.417 249.891 78.373 248.197" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M84.355 230.175C82.3864 234.402 79.9906 238.43 77.2285 242.199" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M169.033 229.259C150.522 234.753 132.256 241.086 114.295 248.197" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M163.997 255.354C161.265 258.284 158.854 261.488 156.764 264.891" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M156.733 270.263C158.564 273.025 160.396 275.787 162.212 278.549" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M169.613 281.845C177.716 284.348 185.834 286.85 193.937 289.338" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M241.335 254.789C249.133 259.031 256.946 263.258 264.744 267.485" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M283.59 265.868C279.683 266.829 275.792 267.791 271.885 268.752" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M280.401 244.839C280.721 249.555 281.667 254.224 283.224 258.696" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M159.48 151.86L130.12 164.144L62.3347 161.764L61.877 181.617H61.9227L129.326 186.622C151.026 187.294 151.026 186.622 173.245 182.365" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M62.3346 161.764C59.6031 159.551 53.9569 155.172 50.2029 153.783C45.0908 151.906 32.1656 153.768 32.1656 153.768L4.95694 155.126C3.15625 155.126 1.70654 156.575 1.70654 158.376C1.70654 159.277 2.07278 160.085 2.65266 160.68C3.2478 161.26 4.0566 161.627 4.95694 161.627H26.1378C26.1378 161.627 24.1235 163.275 23.5284 165.762C22.918 168.249 25.4054 169.348 27.1603 168.799C27.1603 168.799 27.1145 171.744 29.8918 171.881C29.8918 171.881 28.8236 175.574 31.2194 177.115C31.2194 177.115 29.9376 182.792 33.6611 182.792C37.3693 182.792 38.6511 182.792 38.6511 182.792C38.6511 182.792 42.5882 185.509 49.5773 185.509C54.3384 185.509 59.2521 183.128 61.9226 181.617" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M37.1708 161.764C36.6367 162.695 36.0111 163.58 35.3091 164.373C33.2032 166.754 30.3954 168.387 27.1755 168.799" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M31.2195 177.116C31.2195 177.116 36.6368 177.619 41.1232 176.337C44.8162 175.3 46.9831 170.233 41.2148 169.089" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M43.046 176.887C43.046 176.902 46.9221 181.633 38.6511 182.792" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M29.8918 171.881C29.8918 171.881 38.4985 171.21 41.0469 169.226C41.108 169.18 41.1537 169.135 41.2148 169.089C43.7785 166.861 42.283 162.725 35.3091 164.373" stroke="#272223" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M62.3347 174.964L76.4655 175.803" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Direction