import React from "react"

const MapPin = () => (
    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
        <path d="M6.72922 19.8204C1.05352 11.5923 0 10.7478 0 7.72388C0 3.58173 3.35785 0.223877 7.5 0.223877C11.6421 0.223877 15 3.58173 15 7.72388C15 10.7478 13.9465 11.5923 8.27078 19.8204C7.89832 20.3584 7.10164 20.3584 6.72922 19.8204ZM7.5 10.8489C9.2259 10.8489 10.625 9.44978 10.625 7.72388C10.625 5.99798 9.2259 4.59888 7.5 4.59888C5.7741 4.59888 4.375 5.99798 4.375 7.72388C4.375 9.44978 5.7741 10.8489 7.5 10.8489Z" fill="#272223"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="15" height="20" fill="white" transform="translate(0 0.223877)"/>
        </clipPath>
        </defs>
    </svg>

)

export default MapPin