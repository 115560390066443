import React from "react"

const ArrowDown = () => (
    <svg width="239" height="146" viewBox="0 0 239 146" fill="none" xmlns="http://www.w3.org/2000/svg">
        <desc>Illustration of a chart depicting growth</desc>
        <path d="M61 90.5C64.0709 99.5335 74.4725 107.246 77.2362 114.363C78.1575 117.101 76.315 122.81 77.2362 125L2 139L61 90.5Z" fill="#61D0EC"/>
        <path d="M80.3999 124.836L49.8999 121.336L46.0999 121.136L44.0999 109.236L43.2999 104.336L45.7 102.336C45.8 102.336 45.7999 102.336 45.8999 102.136L51.7999 97.2363L60.5999 90.0363L63.3999 87.6363L87.5999 97.6363L107.2 105.736L122.3 91.1363L139 74.9363L188.2 27.1363L169 7.93633L237.3 1.83632L231.2 70.1363L213.2 52.1363L117.9 145.036L106.1 138.736L102.2 136.636L80.3999 124.836Z" fill="#61D0EC"/>
        <path d="M60.5 90L1.69995 138.736" stroke="#272223" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M87.5 97.7363L107.2 105.736L122.2 91.1363L139 74.9363L188.2 27.0363" stroke="#272223" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M60.5 90.1363L63.3 87.7363L87.5 97.7363" stroke="#272223" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M79.5 124.5L1.69995 138.736" stroke="#272223" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M213.2 52.0363L117.9 144.936L106.1 138.536L102.2 136.536L80.3999 124.836" stroke="#272223" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M213.2 52.0363L231.1 69.9363L237.2 1.73633L169 7.83632L188.2 27.0363" stroke="#272223" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M188.2 67.5363L131.6 122.136" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M142.8 79.2363L116.7 104.336" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M100.1 109.336L70.7 97.7363" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50.1 117.336L76.3 119.936" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.4 126.136L14 132.436" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M231.5 11.3363L227.9 49.4363" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M184.6 12.6363L214 9.13632" stroke="#272223" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default ArrowDown